import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { DAHero } from "../components/digital-assets/da-hero"
import { DAAbout } from "../components/digital-assets/da-about"
import { DAOurTeam } from "../components/digital-assets/da-our-team"
import { DAGlobalFootprint } from "../components/digital-assets/da-global-footprint"
import { DAOurPartners } from "../components/digital-assets/da-our-partners"
import { DAWorkTogether } from "../components/digital-assets/da-work-together"
import { DAOffices } from "../components/digital-assets/da-offices"
import { DAContact } from "../components/digital-assets/da-contact"
import { processHexagonData } from "../helpers/common"
import BHDigitalModal from "../components/bh-digital-modal"
import useModalProps from "../helpers/use-modal-props"

const DigitalAssetsTemplate = ({
  data: {
    wpgraphql: {
      page: { title, digitalassetsacf },
    },
  },
}) => {
  const hexagonData = processHexagonData([
    digitalassetsacf?.aboutSection?.name,
    digitalassetsacf?.teamSection?.name,
    digitalassetsacf?.partnersSection?.name,
    digitalassetsacf?.workTogetherSection?.name,
    digitalassetsacf?.contactSectionName,
  ])

  useEffect(() => {}, [])

  const { modalProps } = useModalProps({
    initialOpen:
      typeof window !== "undefined"
        ? window.localStorage.getItem("bh-disclosureNoticesStatus") !==
          "accepted"
          ? false
          : true
        : false,
  })
  const { modalStatus } = modalProps
  // console.log(digitalassetsacf,'Quick fix')

  return (
    <Layout
      logo="digital-assets"
      disableAgreementModal={true}
      onAccept={() => {
        modalProps?.openModal({})
      }}
      modalStateOverride={!modalStatus?.open}
    >
      <SEO
        title={title}
        description={
          "Brevan Howard Digital focuses on the future of digital asset investment, offering cutting-edge solutions in the digital finance sector."
        }
        canonical={"https://www.brevanhoward.com/brevanhoward-digital"}
      />

      <BHDigitalModal
        modalProps={modalProps}
        digitalassetsacf={digitalassetsacf}
      />

      <DAHero
        title={digitalassetsacf?.heroSectionTitle}
        background={digitalassetsacf?.heroSectionBackground}
      />
      <DAAbout
        image1={digitalassetsacf?.aboutSection?.image}
        image1Mobile={digitalassetsacf?.aboutSection?.imageMobile}
        ceoImage={digitalassetsacf?.aboutSection?.ceoPhoto}
        hexagonData={hexagonData[0]}
        title={digitalassetsacf?.aboutSection?.title}
        text={digitalassetsacf?.aboutSection?.text}
        textRight={digitalassetsacf?.aboutSection?.textRight}
        quoteText={digitalassetsacf?.aboutSection?.quoteText}
        companyPosition={digitalassetsacf?.aboutSection?.companyPosition}
        fullName={digitalassetsacf?.aboutSection?.fullName}
        quoteFootnote={digitalassetsacf?.aboutSection?.quoteFootnote}
      />
      <DAOurTeam
        hexagonData={hexagonData[1]}
        title={digitalassetsacf?.teamSection?.title}
        textLeft={digitalassetsacf?.teamSection?.textLeft}
        textRight={digitalassetsacf?.teamSection?.textRight}
        diagramDescription={digitalassetsacf?.teamSection?.diagramDescription}
        footnote={digitalassetsacf?.teamSection?.footnote}
        primaryStat={digitalassetsacf?.teamSection?.primaryStat}
        secondaryStats={digitalassetsacf?.teamSection?.secondaryStats}
      />
      <DAGlobalFootprint
        secondaryTitle={digitalassetsacf?.footprintSection?.secondaryTitle}
        text={digitalassetsacf?.footprintSection?.text}
        title={digitalassetsacf?.footprintSection?.title}
      />
      <DAOurPartners
        hexagonData={hexagonData[2]}
        items={digitalassetsacf?.partnersSection?.items}
        text={digitalassetsacf?.partnersSection?.text}
        title={digitalassetsacf?.partnersSection?.title}
        riskFactorsText={digitalassetsacf?.partnersSection?.riskFactorsText}
        riskFactorsLinkText={
          digitalassetsacf?.partnersSection?.riskFactorsLinkText
        }
        disclosuresTitle={digitalassetsacf?.partnersSection?.disclosuresTitle}
        disclaimerTextLinkText={
          digitalassetsacf?.partnersSection?.disclaimerTextLinkText
        }
        disclaimerText={digitalassetsacf?.partnersSection?.disclaimerText}
        modalProps={modalProps}
      />

      <DAWorkTogether
        hexagonData={hexagonData[3]}
        title={digitalassetsacf?.workTogetherSection?.title}
      />
      <DAOffices digitalassetsacf={digitalassetsacf} />

      <DAContact
        title={digitalassetsacf?.contactSectionTitle}
        hexagonData={hexagonData[4]}
      />
    </Layout>
  )
}

export default DigitalAssetsTemplate

export const daTemplateQuery = graphql`
  query DigitalAssetsQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        title
        digitalassetsacf {
          modalFooterTitle
          modalFooterText
          modalCloseButtonLabel
          modalAcceptButtonLabel
          disclosuresModalData {
            title
            content {
              text
              title
            }
          }
          riskFactorsModalData {
            title
            content {
              text
              title
            }
          }

          heroSectionTitle
          heroSectionBackground {
            mediaItemUrl
            mediaItemId
            modified
            localImageFile {
              childImageSharp {
                original {
                  src
                  width
                }
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }

          aboutSection {
            title
            text
            textRight
            quoteText
            name
            quoteFootnote
            imageMobile {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            image {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            fullName
            fieldGroupName
            companyPosition
            ceoPhoto {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }

          teamSection {
            diagramDescription
            footnote
            name
            textLeft
            title
            textRight
            secondaryStats {
              item {
                number
                sufix
                text
              }
            }
            primaryStat {
              number
              sufix
              text
            }
          }

          footprintSection {
            secondaryTitle
            text
            title
          }

          partnersSection {
            riskFactorsText
            riskFactorsLinkText
            disclosuresTitle
            disclaimerTextLinkText
            disclaimerText

            items {
              itemIcon
              title
            }
            name
            text
            title
          }

          workTogetherSection {
            title
            name
          }

          offices {
            directionsButtonText
            title
            locations {
              city
              country
              items {
                address
                link
              }
            }
          }

          contactSectionName
          contactSectionTitle
        }
      }
    }
  }
`
